
"use client";
import { useEffect, useState } from 'react';
import { Popup } from "antd-mobile";
import Swiper from './Swiper'
import { getHomeYinLiu } from '@/api/yinliu'
import s from './index.module.css'
import OpenApp from "@/components/OpenApp";
import useReport from "@/hooks/useReport";

const Main = ({ visiblePopup, setVisiblePopup, hckukuid, innerfromid }) => {
  const [datas, setDatas] = useState([])
  const [current, setCurrent] = useState(0);
   
  const [visible, setVisible] = useState(false);  // 存在引流的推荐车源数据就弹层
  const { reportClick } = useReport();
  const [schemeURL, setSchemeURL] = useState('')
  const [elmcntt, setElmcntt] = useState('')

  useEffect(() => {
    if (visiblePopup) {
      getHomeYinLiu().then(res => {
        if (res?.data?.serialInfo?.length > 0) {
          setDatas(res?.data?.serialInfo || [])
          setVisible(true)  // 存在引流的推荐车源数据就弹层
        } else {
          // 没有引流的推荐车源数据就关闭
          setVisiblePopup(false)
        }
      })
    }
  }, [visiblePopup])

  return (
    <Popup
      visible={visible}
      className="homepopup"
    >
      <div className={s.popup}>
        <div className={s.close} onClick={() => {
          setVisiblePopup(false);
          setVisible(false)
        }}></div>
        <div className={s.content}>
          <div className={s.swiperdiv}>
            <Swiper datas={datas} current={current} setCurrent={setCurrent} setSchemeURL={setSchemeURL} setElmcntt={setElmcntt} />
          </div>
          <div className={s.btn}>
            <OpenApp
              hckukuid={hckukuid}
              innerfromId={innerfromid}
              schemeURL={schemeURL}
              onClick={()=>{
                reportClick("a0040004300000900000", {
                  elmcntt,
                  page_id : 43, // 不知道为啥page_id偶尔会空 偶尔有值的,这里焊死
                });
              }} 
              className={s.open2}
            >
              立即打开
            </OpenApp>
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default Main;  