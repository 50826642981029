"use client";

import { useEffect, useState, useRef } from "react";
import { getiplocation, getmendianshopinfo, getHomeInfo } from "@/api/home";
import GetCurrentCity from "./GetCurrentCity";
import { Toast } from "antd-mobile";
import Cookies from "@/utils/Cookie";
import useRouter from "@/hooks/useRouter";
import { getUrlParams, replaceUrl } from "@/utils/utils";
import Swiper from "./Swiper";
import SearchDom from "./SearchDom";
import useReport from "@/hooks/useReport";
import StoreDom from "./StoreDom";
import PinPaiDom from "./PinPaiDom";
import s from "./IndexDom.module.css";
import GoTop from "@/components/GoTop";
import DownloadBar from "@/components/DownloadBar";
import Appopen from "@/components/AppOpen";
import PopupToApp from './PopupToApp'
import moment from "moment";

let num = 0; // 用途：首次加载 首页数据从服务端来，不用客户端请求
const defaultStoreId = 257778; // 门店id在cookie里是undefined，则写死北京门店
const today = moment().format('YYYYMMDD')
const IndexDom = (props) => {
  const { searchParams, storeId, cityData, initList } = props;
  const router = useRouter();
  const { reportClick } = useReport(43);
  const ToastRef = useRef();

  // const [cityData] = useCookie("city");
  const [storeIdNow, setStoreIdNow] = useState(storeId);
  const [homeData, setHomeData] = useState(props.homeData);
  const [visiblePopup, setVisiblePopup] = useState(false);
  // 获取滚动前的滚动位置
  const lastScrollTop = useRef();
  const popupDay = useRef();

  // useEffect(() => {
  //   // console.log('useEffect', cityData.cityId, storeId);

  //   if (typeof storeIdNow === "undefined") {
  //     // 门店id在cookie里是undefined，则写死北京门店
  //     //  setStoreIdNow(257778)
  //   } else {
  //     if (num > 0) {
  //       getHomeData();
  //     }
  //   }
  //   ++num;
  //   // console.log('storeId',storeId, num)
  // }, [storeIdNow]);
  // 去客户端缓存，运行时机：客户端加载完毕，服务端从新运行后，客户端数据需要更新
  useEffect(() => {
    changePositionCityLoadingRemove();
    setHomeData(props.homeData);
  }, [props.homeData]);
  useEffect(() => {
    setStoreIdNow(storeId);
  }, [storeId]);

  useEffect(() => {
    lastScrollTop.current = document.documentElement.scrollTop;
    window.addEventListener('scroll', onScrollFun);
    popupDay.current = localStorage.getItem('m_home_popupday') || '';

    return () => {
      window.removeEventListener('scroll', onScrollFun);
    }
  }, [])

  const onScrollFun = () => {
    if (+popupDay.current < today) {
      const scrollTop = document.documentElement.scrollTop;
      if (lastScrollTop.current < scrollTop) {
        setVisiblePopup(true);
        localStorage.setItem('m_home_popupday', today);
        popupDay.current = today;
      }
      lastScrollTop.current = scrollTop
    }
  }

  // 获取门店数据
  // 用途：获取到经纬度以后，刷新一下首页门店距离
  // 当前选择城市和定位城市相同， 首页接口才会返回距离，才有必要刷新首页接口
  const getStoreData = async () => {
    // 当前选择城市和定位城市相同， 首页接口才会返回距离，才有必要刷新首页接口
    // let newStoreId = "";
    // 如果url没有指定门店，则获取门店接口-最近的门店
    // if (typeof window !== "undefined") {
    //   if (!getUrlParams('storeId')) {
    //     Cookies.remove("storeId"); // url没有指定门店ID，则删除storeId,从新获取最近的门店

    //     const storeRes = await getmendianshopinfo({
    //       cityId: cityData.cityId,
    //     });
    //     // console.log('storeData2',storeData2, storeId);
    //     if (storeRes ) {
    //       const { data: storeData2 = {} } = storeRes;

    //       // 门店不一致
    //       if(+storeData2?.dealerStoreID !== +storeIdNow){
    //         setStoreIdNow(storeData2?.dealerStoreID);
    //         Cookies.set("storeId", storeData2?.dealerStoreID);
    //         window.location.href = replaceUrl(window.location.href, {storeId : storeData2?.dealerStoreID})
    //       }else{
    //         // 即便没有获取到门店id或者没变，因为有了经纬度也需要刷新一下首页数据
    //         getHomeData();
    //       }
    //     }else{
    //       // 即便没有获取到门店id或者没变，因为有了经纬度也需要刷新一下首页数据
    //       getHomeData();
    //     }
    //   }else{
    //     // 即便没有获取到门店id或者没变，因为有了经纬度也需要刷新一下首页数据
    //     getHomeData();
    //   }
    // }
    // if (!sessionStorage.getItem("changedStore")) {
    //   const req = new URL(window.location);
    //   req.searchParams.delete("storeId");
    //   Cookies.remove("city");
    //   Cookies.remove("storeId");
    //   sessionStorage.setItem("changedStore", true);
    //   router.push(req.href);
    // } else {
    getHomeData();
    // }

    // console.log(storeData2?.dealerStoreID, storeIdNow, storeData2?.dealerStoreID === storeIdNow)
    //  经纬度获取完毕的回调，如果storeId没有变化也要触发一次
    // if (storeData2?.dealerStoreID === storeIdNow) {
    // getHomeData();
    // }
  };
  // 获取首页数据
  const getHomeData = async () => {
    const p = {
      cityId: cityData.cityId,
    };
    if (storeIdNow) {
      p.storeId = storeIdNow;
    }
    // console.log('getHomeData', cityData.cityId,storeId );
    const homeRes = await getHomeInfo(p);
    if (homeRes) {
      const { data: homeData2 = {} } = homeRes;
      setHomeData(homeData2);
    }
  };

  // 切换到定位城市时的loading
  const changePositionCityLoadingAdd = () => {
    ToastRef.current = Toast.show({
      icon: "loading",
      duration: 0,
    });
  };
  const changePositionCityLoadingRemove = () => {
    Toast.clear();
    ToastRef.current?.clear?.();
  };

  return (
    <>
      <DownloadBar hckukuid="c10027" innerfromid="163" />
      <div className={s.top2}>
        <GetCurrentCity
          changePositionCityLoadingAdd={changePositionCityLoadingAdd}
          cityData={cityData}
          getStoreData={getStoreData}
        />
        <Swiper />
        <div className={s.fuceng}>
          <div className={s.fuceng2}>
            <SearchDom />
            <StoreDom homeData={homeData} cityData={cityData} />
            <PinPaiDom homeData={homeData} cityData={cityData} />
          </div>
        </div>
      </div>
      <GoTop />
      <Appopen hckukuid="c10028" innerfromid="162" />
      <PopupToApp visiblePopup={visiblePopup} setVisiblePopup={setVisiblePopup} hckukuid="c10280" innerfromid="636" />
    </>
  );
};

export default IndexDom;
