'use client';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Controller } from 'swiper';
import useRouter from '@/hooks/useRouter';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/autoplay';

import s from './Swiper.module.css';

// param@datas{Array} 轮播图片 格式：
// [
//   {
//   serialId : 1,
//   serialName : '奥迪',
//   serialImage : 'https://img7.taocheche.com/05/2c11ca95-1125000n94.png',
//   activityType : 14,
//   carCount : 22,
//   maxReducePrice : 6000,
//   maxReducePriceUnit : '元'
//  }
// ]
const SwiperNew = ({ datas = [], current, setCurrent, setSchemeURL, setElmcntt }) => {
  const router = useRouter();

  const dic = {
    21 : '秒杀', 14 : '特价', 22 : '膨胀金'
  }
  useEffect(()=>{
    if(datas?.length === 1){
      setElmcntt(dic[datas[0].activityType])
      setSchemeURL(`bitautousedcar://?platform=web&action=openCarList&activityTypes=${datas[0].activityType}`)
    }
  }, [datas])

  const renderTop = item => {
    if ([21, 22].indexOf(item.activityType) > -1) {
      return (
        <div className={s.topdiv1}>
          <div className={s.font3}>{item.carCount}</div>
          <div>辆车正在参加</div>
          <div
            className={`${s.icon1} ${item.activityType === 21 ? s.miaosha : ''} ${s.left8}`}
          ></div>
        </div>
      );
    }

    return (
      <div className={s.topdiv1}>
        <div className={s.font3}>{item.carCount}</div>
        <div>辆车已降价</div>
      </div>
    );
  };

  const renderTop2 = item => {
    if ([21, 14].indexOf(item.activityType) > -1) {
      return (
        <div className={s.top2div2}>
          <div className={`${s.icon2}`}></div>
          <div className={`${s.font1} ${s.left4}`}>{item.maxReducePrice}</div>
          <div className={`${s.font2} ${s.left2} ${s.bottom2}`}>{item.maxReducePriceUnit}</div>
        </div>
      );
    }

    // 膨胀券
    return (
      <div className={s.top2div2}>
        <div className={`${s.font1}`}>{item.payAmount}</div>
        <div className={`${s.font2} ${s.bottom2}`}>元</div>
        <div className={`${s.font2} ${s.left8}  ${s.bottom2}`}>抵</div>
        <div className={`${s.font1} ${s.left8}`}>{item.maxReducePrice}</div>
        <div className={`${s.font2} ${s.bottom2}`}>{item.maxReducePriceUnit}</div>
        <div className={`${s.icon3}`}></div>
      </div>
    );
  };
 
  return (
    <div className={`${s.wai}`}>
      <Swiper
        autoplay={datas?.length > 1  ? { delay: 3000, disableOnInteraction: false } : false}
        loop = {datas?.length > 1  }
        loopedSlides={datas.length}
        onActiveIndexChange={(swiper) => {
          const idx = swiper.activeIndex % datas.length
          setCurrent(idx);
          
          setElmcntt(dic[datas[idx].activityType])
          setSchemeURL(`bitautousedcar://?platform=web&action=openCarList&activityTypes=${datas[idx].activityType}`)
        }}
        modules={[Autoplay, Controller]}
        className={s.swiperClass}
      >
        {datas?.map((item = {}, i) => {
          return (
            <SwiperSlide className={s.carousel__item__pic} key={+i} onClick={() => { }}>
              <div className={s.imagediv}>
                <Image
                  alt=""
                  src={item?.serialImage}
                  draggable={false}
                  className={s.imagediv}
                  fill
                />
              </div>
              <div className={s.contentdiv}>
                <div className={s.topdiv}>{renderTop(item)}</div>
                <div className={s.top2div}>{renderTop2(item)}</div>
              </div>
            </SwiperSlide>
          );
        })}

        {datas?.length > 1 ? (<div className={`${s.navigation}`}>
          {datas?.map((item = {}, i) => {
            return (
              <div
                key={`swiperpage_${i}`}
                className={` ${s.swiperPaginationBullet} ${i === current ? s.swiperactive : ''}`}
              ></div>
            );
          })}
        </div>) : null}
      </Swiper>
    </div>
  );
};

export default SwiperNew;
