import qs from "qs";
import fetch from "@/utils/fetch";

// 首页引流
export const getHomeYinLiu = (params) => {
  return fetch(`/c-carbrand-consumer/recommend/getActivitySerialListForHome?${qs.stringify(params)}`, {
    needCode: true,
  });

  // return new Promise((resolve)=>{
  //   const datas = {
  //     code : 0,
  //     data : {
  //       serialInfo : [{
  //         serialId : 2,
  //         serialName : '奥迪',
  //         serialImage : 'https://img7.taocheche.com/05/2c11ca95-1125000n94.png',
  //         activityType : 21,
  //         carCount : 212,
  //         maxReducePrice : 15000,
  //         maxReducePriceUnit : '元'
  //       },{
  //         serialId : 3,
  //         serialName : '奥迪',
  //         serialImage : 'https://img7.taocheche.com/05/2c11ca95-1125000n94.png',
  //         activityType : 22,
  //         carCount : 222,
  //         payAmount : 9.9,
  //         maxReducePrice : 8000,
  //         maxReducePriceUnit : '元'
  //       },{
  //         serialId : 1,
  //         serialName : '奥迪',
  //         serialImage : 'https://img7.taocheche.com/05/2c11ca95-1125000n94.png',
  //         activityType : 14,
  //         carCount : 22,
  //         maxReducePrice : 6000,
  //         maxReducePriceUnit : '元'
  //       }]
  //     }
  //   }
  //   resolve(datas)
  // })
};
